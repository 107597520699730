import React from "react";
import styled from "styled-components";
import { color, font } from "../templates/ui";

const StyledTitleLine = styled.h1`
  position: relative;
  display: inline-block;
  color: ${color.body.base};
  font-family: ${font.family.head};
  font-size: 26px;
  line-height: 1.2rem;
  font-weight: 700;
  background-color: ${color.gray.black};
  padding: 15px 10px 15px 15px;
  margin-bottom: 6px;
  text-transform: uppercase;

  &:before {
    position: absolute;
    display: block;
    content: "";
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${color.primary.base};
    z-index: 1;
  }
`;

const TitleBoxed = ({ children, className }) => (
  <div>
    <StyledTitleLine className={className}>{children}</StyledTitleLine>
  </div>
);

export default TitleBoxed;
