import React from "react";

const PayrollSelect = ({ className }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 216.4 28"
    className={className}
  >
    <g id="logo" transform="translate(-0.009 0)">
      <g id="Layer_2_1_" transform="translate(0.009 0)">
        <g id="ps-logo-color">
          <path
            id="Path_146"
            className="st0"
            d="M20.8,12.5L19.3,11l-1.5,1.5c-1.5,1.5-1.5,3.9,0,5.3l1.5,1.5l1.5-1.5
C22.3,16.4,22.3,14,20.8,12.5L20.8,12.5z"
          />

          <rect
            id="Rectangle_705"
            x="17.5"
            y="20.1"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -9.8157 20.0198)"
            className="st0"
            width="3.5"
            height="3.5"
          />
          <path
            id="Path_148"
            className="st0"
            d="M36.6,10.5l-6.2-6.2c-2.6-2.6-6.8-2.6-9.4,0l0,0L19.3,6l2.5,2.4L23,7.2c1.5-1.5,3.9-1.5,5.3,0
l5.3,5.3c1.5,1.5,1.5,3.9,0,5.3l-5.3,5.3c-1.5,1.5-3.9,1.5-5.3,0l-1.3-1.3l-2.5-2.5l-1.5-1.5c-1.5-1.5-1.5-3.9,0-5.3l1.5-1.5
l-2.5-2.4l-2,2c-2.6,2.6-2.6,6.8,0,9.4l0,0l2,2l2.5,2.5l1.7,1.7c2.6,2.6,6.8,2.6,9.4,0l6.1-6.2C39.2,17.3,39.2,13.1,36.6,10.5
L36.6,10.5z"
          />

          <rect
            id="Rectangle_706"
            x="17.5"
            y="6.8"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -0.3897 16.146)"
            className="st0"
            width="3.5"
            height="3.5"
          />
          <path
            id="Path_149"
            className="st0"
            d="M23.7,10.5l-2-2L19.3,6l-1.7-1.7c-2.6-2.6-6.8-2.6-9.4,0l0,0L2,10.5c-2.6,2.6-2.6,6.8,0,9.4
l0,0L8.1,26c2.6,2.6,6.8,2.6,9.4,0l1.7-1.7l-2.5-2.5l-1.3,1.3c-1.5,1.5-3.9,1.5-5.3,0l-5.3-5.3c-1.5-1.5-1.5-3.9,0-5.3l5.3-5.3
c1.5-1.5,3.9-1.5,5.3,0l1.3,1.3l2.5,2.4l1.5,1.5c1.5,1.5,1.5,3.9,0,5.3l-1.5,1.5l2.5,2.5l2-2C26.3,17.3,26.3,13.1,23.7,10.5z"
          />
          <path
            id="Path_152"
            className="st0"
            d="M50,7.6c0.2,0,0.4,0.2,0.4,0.4l0,0v1.4h0.1c0.2-0.3,0.4-0.5,0.7-0.8s0.6-0.5,1-0.7
c0.4-0.2,0.9-0.4,1.3-0.5c0.6-0.1,1.1-0.2,1.7-0.2c0.9,0,1.8,0.2,2.7,0.5C58.7,8,59.4,8.5,60,9.2c0.6,0.6,1.1,1.4,1.4,2.2
c0.3,0.9,0.5,1.8,0.5,2.7s-0.2,1.9-0.5,2.8c-0.3,0.8-0.7,1.6-1.3,2.2s-1.3,1.2-2.1,1.5c-0.9,0.4-1.8,0.6-2.8,0.6
c-0.9,0-1.8-0.2-2.6-0.6c-0.8-0.3-1.4-0.9-1.9-1.6h-0.1v8c0,0.2-0.2,0.4-0.4,0.4h-2.5c-0.2,0-0.4-0.2-0.4-0.4l0,0V8.1
c0-0.2,0.2-0.4,0.4-0.4l0,0L50,7.6z M58.3,14.2c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-1-0.7-1.4s-0.7-0.7-1.2-1
C55.6,10.1,55,10,54.4,10s-1.2,0.1-1.7,0.4c-1,0.5-1.7,1.4-2,2.4c-0.2,0.5-0.3,1-0.3,1.6c0,0.5,0.1,1,0.3,1.5s0.4,1,0.8,1.4
c0.3,0.4,0.8,0.7,1.3,1c1.1,0.5,2.3,0.5,3.4,0c0.5-0.2,0.9-0.6,1.2-1s0.6-0.9,0.7-1.4C58.2,15.3,58.3,14.8,58.3,14.2L58.3,14.2z"
          />
          <path
            id="Path_153"
            className="st0"
            d="M73.4,19.3L73.4,19.3c-0.5,0.6-1,1.2-1.7,1.5c-0.8,0.4-1.7,0.6-2.6,0.6
c-0.6,0-1.2-0.1-1.7-0.2c-0.6-0.1-1.1-0.4-1.6-0.7c-0.5-0.3-0.9-0.8-1.2-1.3c-0.3-0.6-0.5-1.3-0.5-2c0-0.9,0.3-1.7,0.9-2.4
c0.6-0.6,1.4-1.1,2.2-1.4c1-0.3,2-0.5,3-0.6c1.1-0.1,2.2-0.2,3.2-0.2v-0.3c0.1-0.7-0.3-1.4-0.9-1.9C71.8,10,71,9.8,70.3,9.9
c-0.7,0-1.4,0.2-2.1,0.4c-0.5,0.2-0.9,0.5-1.3,0.8c-0.2,0.1-0.5,0.1-0.6,0l-1.2-1.4c-0.2-0.2-0.1-0.5,0-0.6l0,0
c0.7-0.6,1.5-1,2.3-1.3c1-0.3,2-0.5,3.1-0.5c1,0,2,0.1,3,0.5C74.3,8,74.9,8.5,75.4,9c0.4,0.5,0.8,1.1,0.9,1.8
c0.2,0.6,0.3,1.3,0.3,2v7.6c0,0.2-0.2,0.4-0.4,0.4h-2.3c-0.2,0-0.4-0.2-0.4-0.4L73.4,19.3L73.4,19.3z M73.4,14.9h-0.8
c-0.6,0-1.2,0-1.7,0.1c-0.6,0-1.1,0.1-1.7,0.3c-0.5,0.1-0.9,0.4-1.3,0.7c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.1,0.6,0.2,0.8
c0.1,0.2,0.3,0.4,0.5,0.5s0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.1,0.8,0.1c1,0.1,1.9-0.3,2.7-0.9c0.6-0.7,1-1.6,0.9-2.5L73.4,14.9z"
          />
          <path
            id="Path_154"
            className="st0"
            d="M79.2,7.6H82c0.2,0,0.3,0.1,0.4,0.3l3.7,9.4h0.1l3.3-9.4c0.1-0.2,0.2-0.3,0.4-0.3h2.6
c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1,0,0.2l-6.2,15.5c-0.2,0.6-0.5,1.1-0.8,1.6s-0.6,0.9-1,1.2c-0.4,0.3-0.9,0.6-1.4,0.7
c-0.6,0.2-1.3,0.3-1.9,0.2c-0.3,0-0.6,0-0.9,0c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.4-0.3-0.4-0.5l0.2-2c0-0.2,0.2-0.4,0.5-0.4l0,0h0.2
c0.2,0,0.4,0,0.6,0c0.3,0,0.6,0,0.9-0.1c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.3-0.4,0.5-0.7c0.1-0.3,0.3-0.6,0.4-0.9l0.7-1.7
L78.8,8.3c-0.1-0.2,0-0.5,0.2-0.6C79.1,7.7,79.2,7.6,79.2,7.6L79.2,7.6z"
          />
          <path
            id="Path_155"
            className="st0"
            d="M95.8,7.6h2.4c0.2,0,0.4,0.2,0.4,0.4v1.8h0.1c0.4-0.8,0.9-1.4,1.6-1.9
c0.7-0.5,1.5-0.7,2.4-0.7h0.4c0.2,0,0.4,0.2,0.4,0.4v2.2c0,0.2-0.2,0.4-0.4,0.4c0,0,0,0-0.1,0h-0.1c-0.2,0-0.3,0-0.5,0
c-0.6,0-1.3,0.1-1.9,0.4c-0.4,0.2-0.8,0.6-1.1,1c-0.2,0.4-0.4,0.8-0.5,1.2c-0.1,0.3-0.1,0.6-0.1,1v6.7c0,0.2-0.2,0.4-0.4,0.4
h-2.5c-0.2,0-0.4-0.2-0.4-0.4V8.1C95.4,7.8,95.6,7.7,95.8,7.6z"
          />
          <path
            id="Path_156"
            className="st0"
            d="M105.2,14.2c0-1,0.2-2,0.6-2.9c0.4-0.8,0.9-1.6,1.6-2.2c0.7-0.6,1.5-1.1,2.3-1.4
c1.9-0.7,3.9-0.7,5.8,0c0.9,0.3,1.7,0.8,2.3,1.4c0.7,0.6,1.2,1.4,1.6,2.2c0.4,0.9,0.6,1.9,0.6,2.9s-0.2,2-0.6,2.9
c-0.4,0.8-0.9,1.6-1.6,2.2s-1.5,1.1-2.3,1.5c-1.9,0.7-3.9,0.7-5.8,0c-0.9-0.3-1.7-0.8-2.3-1.5c-0.7-0.6-1.2-1.4-1.6-2.2
C105.3,16.2,105.1,15.2,105.2,14.2z M108.6,14.2c0,0.5,0.1,1.1,0.2,1.6c0.2,0.5,0.4,1,0.7,1.4c0.3,0.4,0.7,0.7,1.2,1
c1.1,0.5,2.4,0.5,3.5,0c0.5-0.2,0.9-0.6,1.2-1c0.3-0.4,0.6-0.9,0.7-1.4c0.2-0.5,0.2-1,0.2-1.6c0-0.5-0.1-1-0.2-1.5
c-0.2-0.5-0.4-1-0.7-1.4c-0.3-0.4-0.7-0.7-1.2-1c-1.1-0.5-2.4-0.5-3.5,0c-0.5,0.2-0.9,0.6-1.2,1c-0.3,0.4-0.6,0.9-0.7,1.4
C108.7,13.2,108.6,13.7,108.6,14.2L108.6,14.2z"
          />
          <path
            id="Path_157"
            className="st0"
            d="M123.1,0h2.5c0.2,0,0.4,0.2,0.4,0.4v20.1c0,0.2-0.2,0.4-0.4,0.4h-2.5c-0.2,0-0.4-0.2-0.4-0.4
V0.4C122.6,0.2,122.8,0,123.1,0z"
          />
          <path
            id="Path_158"
            className="st0"
            d="M129.2,0h2.5c0.2,0,0.4,0.2,0.4,0.4l0,0v20.1c0,0.2-0.2,0.4-0.4,0.4l0,0h-2.5
c-0.2,0-0.4-0.2-0.4-0.4l0,0V0.4C128.8,0.2,129,0,129.2,0L129.2,0z"
          />
          <path
            id="Path_159"
            className="st0"
            d="M149.2,10.9c-0.2,0.1-0.4,0.1-0.6,0c-0.3-0.3-0.6-0.5-1-0.7c-0.5-0.3-1.1-0.4-1.7-0.4
c-0.5,0-1,0.1-1.5,0.3c-0.4,0.2-0.7,0.6-0.7,1.1s0.3,1,0.8,1.1c0.7,0.3,1.4,0.5,2.2,0.7c0.5,0.1,1,0.3,1.5,0.4
c0.5,0.2,1,0.4,1.4,0.7s0.8,0.7,1,1.1c0.3,0.5,0.4,1.1,0.4,1.6c0,0.7-0.1,1.4-0.5,2.1c-0.3,0.5-0.7,1-1.2,1.4
c-0.6,0.4-1.2,0.6-1.8,0.7c-0.7,0.2-1.4,0.2-2.1,0.2c-1.1,0-2.1-0.2-3.1-0.6c-0.8-0.3-1.5-0.7-2.1-1.3c-0.2-0.2-0.2-0.4,0-0.6
l0,0l1.6-1.4c0.2-0.2,0.4-0.2,0.6,0c0.3,0.4,0.7,0.7,1.2,0.9c0.6,0.3,1.3,0.5,2,0.5c0.3,0,0.5,0,0.8-0.1c0.2-0.1,0.5-0.1,0.7-0.3
c0.2-0.1,0.4-0.3,0.5-0.5s0.2-0.5,0.2-0.8c0-0.5-0.3-1-0.8-1.2c-0.8-0.3-1.5-0.6-2.3-0.7c-0.5-0.1-1-0.2-1.5-0.4
s-0.9-0.4-1.3-0.7c-0.4-0.3-0.7-0.6-0.9-1.1s-0.4-1-0.3-1.6c0-0.7,0.2-1.3,0.5-1.9c0.3-0.5,0.7-1,1.2-1.3
c0.5-0.3,1.1-0.6,1.7-0.8c0.6-0.2,1.3-0.2,2-0.2c1,0,1.9,0.2,2.9,0.5c0.7,0.2,1.4,0.6,1.9,1.2c0.2,0.2,0.2,0.4,0,0.6l0,0
L149.2,10.9z"
          />
          <path
            id="Path_160"
            className="st0"
            d="M167.3,14.5V15c0,0.2-0.2,0.4-0.4,0.4H157c0,0.5,0.2,0.9,0.4,1.3s0.5,0.7,0.9,1
s0.8,0.5,1.2,0.7c0.5,0.2,0.9,0.3,1.4,0.2c0.7,0,1.5-0.1,2.1-0.5c0.4-0.2,0.8-0.5,1.2-0.9c0.2-0.2,0.4-0.2,0.6-0.1l1.6,1.2
c0.2,0.2,0.2,0.4,0.1,0.6l0,0c-1.4,1.6-3.4,2.4-5.5,2.3c-1,0-2-0.2-2.9-0.5c-0.8-0.3-1.6-0.8-2.3-1.4s-1.2-1.3-1.5-2.2
c-0.4-0.9-0.6-1.9-0.5-2.9c0-1,0.2-2,0.5-2.9c0.3-0.8,0.9-1.6,1.5-2.2c0.7-0.6,1.4-1.1,2.3-1.4c0.9-0.3,1.9-0.5,2.8-0.5
c0.9,0,1.8,0.1,2.6,0.5c0.8,0.3,1.5,0.8,2.1,1.4s1.1,1.4,1.4,2.2C167.2,12.3,167.3,13.4,167.3,14.5z M163.9,13
c0-0.4-0.1-0.8-0.2-1.2c-0.1-0.4-0.3-0.7-0.6-1s-0.6-0.5-1-0.7c-0.5-0.2-0.9-0.3-1.4-0.3c-0.9,0-1.9,0.3-2.6,0.9
c-0.7,0.6-1.1,1.4-1.2,2.3H163.9z"
          />
          <path
            id="Path_161"
            className="st0"
            d="M170.8,0h2.5c0.2,0,0.4,0.2,0.4,0.4l0,0v20.1c0,0.2-0.2,0.4-0.4,0.4l0,0h-2.5
c-0.2,0-0.4-0.2-0.4-0.4l0,0V0.4C170.3,0.2,170.5,0,170.8,0L170.8,0z"
          />
          <path
            id="Path_162"
            className="st0"
            d="M190.2,14.5V15c0,0.2-0.2,0.4-0.4,0.4h-9.9c0,0.5,0.2,0.9,0.4,1.3s0.5,0.7,0.9,1
s0.8,0.5,1.2,0.7s0.9,0.3,1.4,0.3c0.7,0,1.5-0.1,2.1-0.5c0.4-0.2,0.8-0.5,1.2-0.9c0.2-0.2,0.4-0.2,0.6-0.1l1.6,1.2
c0.2,0.2,0.2,0.4,0.1,0.6l0,0c-1.4,1.6-3.4,2.4-5.5,2.3c-1,0-2-0.2-2.9-0.5c-0.8-0.3-1.6-0.8-2.3-1.4s-1.2-1.3-1.5-2.2
c-0.4-0.9-0.6-1.9-0.5-2.9c0-1,0.2-2,0.5-2.9c0.3-0.8,0.9-1.6,1.5-2.2c0.7-0.6,1.4-1.1,2.3-1.4c0.9-0.3,1.9-0.5,2.8-0.5
c0.9,0,1.8,0.1,2.6,0.5c0.8,0.3,1.5,0.8,2.1,1.4s1.1,1.4,1.4,2.2C190.1,12.3,190.2,13.4,190.2,14.5z M186.8,13
c0-0.4-0.1-0.8-0.2-1.2c-0.1-0.4-0.3-0.7-0.6-1s-0.6-0.5-1-0.7c-0.5-0.2-0.9-0.3-1.4-0.3c-0.9,0-1.9,0.3-2.6,0.9
c-0.7,0.6-1.1,1.4-1.2,2.3H186.8z"
          />
          <path
            id="Path_163"
            className="st0"
            d="M203.4,11c-0.2,0.2-0.4,0.2-0.6,0c-0.3-0.3-0.6-0.5-0.9-0.6c-0.5-0.2-1.1-0.4-1.6-0.4
c-1.1,0-2.2,0.5-2.8,1.3c-0.3,0.4-0.6,0.9-0.7,1.4c-0.1,0.5-0.2,1-0.2,1.5s0.1,1,0.2,1.5c0.2,0.5,0.4,1,0.7,1.4s0.7,0.7,1.2,1
s1.1,0.4,1.7,0.4c0.5,0,1.1-0.1,1.6-0.3c0.4-0.1,0.7-0.3,1-0.6c0.2-0.2,0.4-0.2,0.6,0l1.5,1.5c0.2,0.2,0.2,0.4,0,0.6l0,0
c-0.5,0.5-1.2,0.8-1.8,1c-0.9,0.3-1.9,0.5-2.8,0.5c-1,0-1.9-0.2-2.8-0.5c-0.9-0.3-1.7-0.8-2.3-1.4c-0.7-0.6-1.2-1.4-1.6-2.2
c-0.4-0.9-0.6-1.9-0.6-2.9s0.2-2,0.6-2.9c0.4-0.8,0.9-1.6,1.6-2.2c0.7-0.6,1.4-1.1,2.3-1.4c1.8-0.7,3.9-0.7,5.7,0.1
c0.7,0.3,1.3,0.7,1.9,1.2c0.2,0.2,0.2,0.4,0,0.6l0,0L203.4,11z"
          />
          <path
            id="Path_164"
            className="st0"
            d="M207.3,9.9V8.1c0-0.2,0.2-0.4,0.4-0.4l0,0h1.9V4.2c0-0.2,0.2-0.4,0.4-0.4l0,0h2.5
c0.2,0,0.4,0.2,0.4,0.4v3.4h2.9c0.2,0,0.4,0.2,0.4,0.4l0,0v1.8c0,0.2-0.2,0.4-0.4,0.4l0,0H213v6.2c0,0.5,0.1,1,0.3,1.5
c0.2,0.4,0.7,0.6,1.5,0.6c0.2,0,0.5,0,0.7-0.1h0.2c0.2-0.1,0.5,0.1,0.5,0.3v0.1l0.1,1.7c0,0.2-0.1,0.4-0.3,0.4
c-0.2,0.1-0.5,0.1-0.7,0.2c-0.4,0.1-0.8,0.1-1.2,0.1c-0.8,0-1.5-0.1-2.2-0.4c-0.5-0.2-1-0.6-1.3-1s-0.6-0.9-0.7-1.5
c-0.1-0.6-0.2-1.2-0.2-1.8v-6.2h-1.9C207.5,10.3,207.3,10.1,207.3,9.9z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PayrollSelect;
