import React from "react";
import styled from "styled-components";
import { color } from "../templates/ui";
import TitleBoxed from "../atoms/TitleBoxed";
import ReadMoreButton from "../atoms/ReadMoreButton";
import Link from "next/link";

const StyledCaseCardWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledCaseCard = styled.a`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  padding: 25px;
  width: 100%;
  height: 100%;
  min-height: 280px;
  box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0);

  ${(props) => {
    if (props.index) {
      return `background-color: red;`;
    }
  }};

  &:before,
  &:after {
    transition: all 0.2s ease-in-out;
    position: absolute;
    transform: scale(1);
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }

  &:before {
    background: linear-gradient(
      to bottom,
      rgba(10, 11, 9, 0) 0%,
      rgba(10, 11, 9, 1) 100%
    );
    opacity: 1;
  }

  &:after {
    background: ${color.primary.base};
    opacity: 0;
  }

  &:hover,
  &:focus {
    &:before,
    &:after {
      transform: scale(0.95);
    }

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 0.8;
    }

    .bg-img-wrapper {
      transform: scale(0.95);
      box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0.25);
    }

    img {
      transform: scale(1.15);
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    .rm-button {
      color: white;
    }

    .chevron-r {
      fill: white;
    }
  }
`;

const StyledBgImageWrapper = styled.div`
  transition: all 0.2s ease-in-out;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1);
  box-shadow: 0 0 15px 6px rgba(0, 0, 0, 0);
  z-index: -1;
`;

const StyledBgImage = styled.img`
  transition: all 0.2s ease-in-out;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transform: scale(1);
  z-index: 0;

  @media only screen and (max-width: 990px) {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }
`;

const StyledTitleWrapper = styled.div`
  position: relative;
  margin: 0 -25px 0 -40px;
  z-index: 2;
`;

const StyledDescription = styled.p`
  position: relative;
  color: ${color.body.base};
  z-index: 2;
`;

const StyledBottomWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  z-index: 2;

  @media only screen and (min-width: 991px) {
    height: 47px;
  }
`;

const StyledLogo = styled.div`
  svg {
    fill: ${color.body.base};
    vertical-align: bottom;
    opacity: 0.5;
  }
`;

const StyledTitleBoxed = styled(TitleBoxed)`
  line-height: 1.6rem;
  font-size: 18px;

  @media only screen and (min-width: 768px) {
    line-height: 1.2rem;
    font-size: 26px;
  }
`;

const StyledReadMoreButton = styled(ReadMoreButton)`
  align-self: flex-end;
`;

const CaseCard = ({
  image,
  titleLineFirst,
  titleLineSecond,
  description,
  logo,
  href,
  className,
  key,
}) => (
  <StyledCaseCardWrapper className={className} key={key}>
    <Link href={href}>
      <StyledCaseCard className="case-card" href={href}>
        <StyledBgImageWrapper className="bg-img-wrapper">
          <StyledBgImage src={image} />
        </StyledBgImageWrapper>
        <StyledTitleWrapper>
          <StyledTitleBoxed>{titleLineFirst}</StyledTitleBoxed>
          {titleLineSecond && (
            <StyledTitleBoxed>{titleLineSecond}</StyledTitleBoxed>
          )}
        </StyledTitleWrapper>
        <StyledDescription>{description}</StyledDescription>
        <StyledBottomWrapper>
          <StyledReadMoreButton>Ontdek meer</StyledReadMoreButton>
          <StyledLogo>{logo}</StyledLogo>
        </StyledBottomWrapper>
      </StyledCaseCard>
    </Link>
  </StyledCaseCardWrapper>
);

export default CaseCard;
